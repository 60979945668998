<template>
  <div class="home-container">
    <div class="box main-box no-border-print">
      <div>
        <div class="is-flex ide-part is-mobile">
          <div id="ide-tree" class="split project-tree no-border-print is-marginless is-paddingless">
            <ProjectTree @file-changed="fileChanged" :active-file="activeItem" ref="projectTree" :project="project"
                         :editable-types="ideMeta.editableTypes" :markStartFile="markStartFile"/>
          </div>
          <div id="ide-code" class="split is-marginless is-paddingless">
            <div class="active-file-name" v-if="activeItem">{{ activeItemName }}</div>
            <div id="code" class="code-editor no-border-print"></div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile margin-top-10px">
        <div class="column is-6 has-text-left">
          <button class="button execute-button" @click="tryExecute">
            <span v-show="!isCodeExecuting">Run</span>
            <span v-if="isCodeExecuting">
            <font-awesome-icon icon="spinner" class="fa-spin"/></span>
          </button>&nbsp;
          <button class="button stop-button is-mobile-small" @click="stopExecution" v-if="isCodeExecuting">
            <span>Stop</span>
          </button>
        </div>
        <div class="control column is-6 has-text-right">
          <button class="button"
                  @click="reset">Reset</button>&nbsp;
          <button class="button"
                  @click="copyToClipboard"><font-awesome-icon icon="copy"/></button>&nbsp;
          <button class="button has-text-dark has-text-weight-bold"
                  @click="expand">
            <font-awesome-icon icon="expand" v-if="!fullScreen"/>
            <font-awesome-icon icon="compress" v-if="fullScreen"/>
          </button>
        </div>
      </div>
      <DoodleMessage :message="doodleForm.errorMessage" message-class="has-text-danger"
                     v-show="doodleForm.errorMessage"/>
      <div v-show="showOutput && (language==='html' || language==='htmlphp')">
        <div class="has-text-weight-semibold ide-title">Output</div>
        <div class="output">
          <iframe name="output" frameborder="0" sandbox="allow-forms allow-popups allow-scripts allow-same-origin allow-modals"></iframe>
        </div>
      </div>
      <div v-show="showOutput && language!=='html' && language!=='htmlphp'">
        <div class="has-text-weight-semibold ide-title">Output</div>
        <div>
          <div id="output" class="code-editor no-border-print"></div>
        </div>
      </div>
      <div id="ideContainer" class="g-recaptcha is-hidden-print margin-top-20px has-text-centered is-hidden" data-size="invisible" data-sitekey="6LfPlOsUAAAAAIALEFUM1022nNwsyWjpATeuYMdi" data-badge="inline"></div>
    </div>
  </div>
</template>

<script>
import aceMixin from './../assets/javascript/ide-mixin'
import DoodleMessage from '../components/Utils/DoodleMessage'
import formMixin from './../assets/javascript/form-mixin'
import loadScriptInBody from '../assets/javascript/loadScriptInBody'
import { ACE_UTIL } from '@/assets/javascript/ace-util'
import ProjectTree from '@/components/Utils/ProjectTree'
import Split from 'split.js'
import { META } from '@/assets/javascript/meta'
import recaptchaMixin from '../assets/javascript/recaptcha-mixin'
import { IDE_CONST } from '@/assets/javascript/constants';

export default {
  name: 'htmlMultiIde',
  components: { ProjectTree, DoodleMessage },
  mixins: [aceMixin, formMixin, recaptchaMixin],
  data: function () {
    return {
      showOutput: false,
      minimizeTree: false,
      activeItem: '',
      project: null,
      ideMeta: {},
      originalData: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    next((vm) => {
      loadScriptInBody.loadScriptInBody('/assets/jdoodle-pym.min.js')
      ACE_UTIL.loadACELibraries()
    })
    next()
  },
  mounted () {
    if (this.$store.state.robotChecked === false) {
      this.loadRecaptcha('ideContainer', this.doRoboCheck)
    }

    this.isMultiFile = true
    let postAceAction = (count) => {
      if (window.ace) {
        this.pymChild = new window.pym.Child()
        this.pymChild.onMessage('setScript', (data) => {
          data = JSON.parse(data)
          if (data.id === this.$route.query.id) {
            this.ideMeta = META[data.language]
            if (data.versionIndex) {
              this.versionIndex = parseInt(data.versionIndex)
            }
            this.initScripts(data)
          }
        })

        this.pymChild.sendMessage('getScript', JSON.stringify({ id: this.$route.query.id }))
      } else if (count < 20) {
        window._.delay(postAceAction, 600, count + 1)
      }
    }

    let postAction = (count) => {
      if (window.pym) {
        window.pym.Child({ polling: 500 })
        window._.delay(postAceAction, 100, 1)
      } else if (count < 20) {
        window._.delay(postAction, 600, count + 1)
      }
    }

    window._.delay(postAction, 600, 1)

    this.$nextTick(() => {
      this.split = Split(['#ide-tree', '#ide-code'], {
        sizes: [25, 75],
        minSize: [25, 75]
      })
    })
  },

  computed: {
    activeItemName () {
      if (this.activeItem.parent === '/') {
        return '/' + this.activeItem.name
      } else {
        return this.activeItem.parent + '/' + this.activeItem.name
      }
    },
    language () {
      return this.ideMeta.language
    },
    aceLanguageCode () {
      return this.ideMeta.aceCode ? this.ideMeta.aceCode : this.ideMeta.language
    },
    markStartFile () {
      return this.language !== 'html'
    }
  },
  methods: {
    changeTab (tab) {
      this.currentTab = tab
    },
    overrideHome: function (data, proj) {
      if (data.overrideHome === 'true') {
        let homeIndex = proj.treeData.children.findIndex((o) => {
          return proj.home === '/' + o.name
        })

        proj.treeData.children.splice(homeIndex, 1)

        proj.home = ''
      }

      if (data.home) {
        proj.home = data.home
      }

      if (data.dbScript) {
        proj.dbScript = data.dbScript
      }

      if (data.libs) {
        proj.libs = data.libs.split(',')
      }
    },

    formFileStructure: function (data, proj) {
      let getItemIndex = (currentPath, filePath, path) => {
        return currentPath.children.findIndex((o) => {
          return o.name === filePath[path]
        })
      }

      for (let i = 0; i < data.multiFiles.length; i++) {
        let multiFile = data.multiFiles[i]
        let filePath = multiFile.fileName.split('/')

        let currentPath = proj.treeData

        for (let path = 0; path < filePath.length; path++) {
          if (path === filePath.length - 1) {
            let itemIndex = getItemIndex(currentPath, filePath, path)
            if (itemIndex !== -1) {
              currentPath.children[itemIndex].content = multiFile.content
            } else {
              let child = {
                editMode: false,
                isStatic: multiFile.static,
                isReadOnly: multiFile.readOnly,
                markedForDeletion: false,
                name: filePath[path],
                parent: this.getParentNameForFolder(currentPath),
                content: multiFile.content
              }

              if (multiFile.folder) {
                child.children = []
              }

              currentPath.children.push(child)
            }
          } else if (path !== 0) {
            let itemIndex = getItemIndex(currentPath, filePath, path)
            if (itemIndex !== -1) {
              currentPath = currentPath.children[itemIndex]
            } else {
              currentPath.children.push({
                children: [],
                editMode: false,
                isStatic: false,
                markedForDeletion: false,
                name: filePath[path],
                parent: this.getParentNameForFolder(currentPath)
              })
              currentPath = currentPath.children[currentPath.children.findIndex((o) => o.name === filePath[path])]
            }
          }
        }
      }
    },
    initScripts (data) {
      this.originalData = data
      let proj = window._.clone(this.ideMeta.projectStructure)

      this.overrideHome(data, proj)

      this.formFileStructure(data, proj)

      this.project = proj

      this.activeItem = this.project.treeData.children.find((o) => {
        return this.project.home === '/' + o.name
      })

      this.$_.delay(this.initEditors, 500, 1)
    },
    getParentNameForFolder (currentPath) {
      if (!currentPath.parent || currentPath.name === '/') {
        return '/'
      } else if (currentPath.parent === '/') {
        return '/' + currentPath.name
      } else {
        return currentPath.parent + '/' + currentPath.name
      }
    },
    fileChanged (item) {
      this.activeItem = item
    },
    ideExecute () {
      this.$refs.projectTree.syncBeforeExecute()
      this.execute()
    },
    postExecuteSuccessHandling () {

    },
    reset () {
      window.ace.edit(IDE_CONST.OUTPUT_EDITOR).getSession().setValue('')
      this.args = null
      this.stdin = null
      this.javaLibraries = []
      this.executionTime = null
      this.memory = 0
      this.cpuTime = 0
      this.initScripts(this.originalData)
      window.ace.edit(IDE_CONST.CODE_EDITOR).getSession().setValue(this.activeItem.content)
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../node_modules/bulma-switch/dist/css/bulma-switch.min.css";
@import "./../assets/style/ide";

.main-box {
  padding: 0.5em 1.2em;
  background: #F2F2F2;
}

#code {
  min-height: 300px;
}

#code .ace-tomorrow-night .ace_gutter, .toggle-java {
  color: #F99D25;
}

.home-container {
  margin-top: 0.1em;
}

.column {
  padding-top: 0.10em;
  padding-left: 0.50em;;
}

.execute-section {
  text-align: right;
  margin-right: 3%;
  margin-top: 10px;
  position: relative;
}

.project-tree {
  min-height: 100%;
}

</style>
